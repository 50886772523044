body {
  font-family: 'Work Sans', sans-serif;
  background-color: #ffffff;
}

.carousel-indicators {
  margin-bottom: 2rem !important;
}

.carousel-indicators [data-bs-target] {
  z-index: 10000;
  background-color: #000087 !important;
  border-radius: 50% !important;
  height: 10px !important;
  width: 10px !important;
  bottom: 30px !important;
}

.main-heading {
  font-size: 5vh !important;
  font-weight: bold !important;
  color: #3939a0;
  font-family: 'Work Sans', sans-serif !important;
  animation: fadeIn 0.5s forwards;
}

.sub-heading {
  font-size: 3vh !important;
  font-family: 'Yantramanav', sans-serif;
  color: #4e4e4e;
  font-weight: 300 !important;
  animation: fadeIn 0.5s forwards;
}

.sub-para {
  font-size: 2.3vh !important;
  font-family: 'Yantramanav', sans-serif;
  color: #4e4e4e;
  font-weight: 500 !important;
  animation: fadeIn 0.5s forwards;
}
.flag-cont {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
  position: relative !important;
  background-color: '#fff' !important;
  height: 100vh !important;
  margin-top: 5%;
}
.flag-card-head {
  color: #3939a0;
  font-size: 3vh;
  font-weight: bold;
  font-family: 'Work Sans', sans-serif;
}
.flag-card-num {
  color: #7fbe67;
  font-family: Oswald;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: black;
}
.flag_video {
  display: flex;
  justify-content: center;
  height: 55vh;
  border-radius: 5px;
}

.contribute_btn {
  border-radius: 50px;
}
.css-1a1vzvn-MuiPaper-root-MuiAppBar-root {
  z-index: 100 !important;
}
.circle_undertext {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: bold;
}
.target_units {
  font-family: 'Oswald', sans-serif !important;
  font-size: 20px;
  font-weight: bold;
}
.target_units_sub {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #4e4e4e;
}
.contribute_btn {
  font-family: 'Inter', sans-serif;
  font-size: 20px !important;
  font-weight: bold !important;
  color: #000000 !important;
  background-color: #fdfdfd !important;
  border-radius: 50px !important;
  padding: 10px 20px !important;
  text-transform: uppercase !important;
}
.tweet-cont {
  display: flex;
  overflow-x: auto;
}
.analytic-logo {
  height: 5vh;
}
.circle-logo {
  height: 15vh;
}
.clean_logo {
  height: 10vh;
}
.plant_logo {
  height: 30vh;
  mix-blend-mode: darken;
}
.bar_uppertext {
  font-family: Oswald;
  font-weight: semi-bold;
  font-size: 3vh;
}
.carousel-control-next-icon {
  display: none;
}
.carousel-control-prev-icon {
  display: none;
}
.carousel-indicators .active {
  opacity: 0;
}
@media (max-width: 970px) {
  .book_img {
    height: 26vh !important;
  }
  .flag_video {
    display: flex;
    justify-content: center;
    height: 25vh;
  }
  .flag-cont {
    height: auto !important;
  }
  .main-heading {
    font-size: 4vh !important;
  }
  .leaderboard_card_head {
    padding-left: 0px !important;
  }
  .certificate-canvas {
    height: 25vh !important;
    width: auto;
  }
  .flag-card-head {
    font-size: 2vh !important;
  }
  .beforeafterCard {
    width: 20rem !important;
  }
  .css-o69gx8-MuiCardMedia-root {
    max-width: 120px !important;
    max-height: 180px !important;
  }
  .certificate-canvas {
    height: auto !important;
    width: 85vw;
  }
}
.orange_btn {
  background-image: url(./../public/asset/images/orange_btn.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent !important;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  padding: 10px 20px;
  margin-top: 20px;
  min-width: 100px !important;
  min-height: 60px !important;
  outline: none !important;
  border: none !important;
}
.orange_btn :focus {
  border: none !important;
}
.green_btn {
  background-image: url(./../public/asset/images/green_btn.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent !important;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 10px 20px;
  margin-top: 20px;
  min-width: 100px !important;
  min-height: 60px !important;
}

.certificate-canvas {
  height: 56vh;
  width: auto;
  box-shadow: rgba(128, 128, 128, 0.48) 1px 4px 16px;
}

.error-message {
  color: #e46a76;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}
.card-body {
  padding: 8px;
}
.progress,
.progress-stacked {
  height: 30px !important;
  background-color: #95a1ff !important;
}
.progress-bar {
  background-color: #4458f0 !important;
}
.leaderboard_card {
  border: 1.5px solid #1a1a92;
  box-shadow: 0px 0px 2px 0px #171717;
}
.leaderboard_card_head {
  font-weight: bold;
  color: #1a1a92;
  padding-left: 20px;
  display: flex;
  border-right: 2px solid #1a1a92;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}
.indexedStep {
  color: white;
  width: 30px;
  height: 30px;
  font-size: 12px;
  background-color: #1343009a;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.indexedStep.accomplished {
  background-color: #7fbe67;
  color: #000;
  font-weight: bold;
}
.hlWBHk {
  max-height: 80px !important;
}
.kKnUeT {
  color: #3939a0 !important;
  font-size: 16px !important;
}
.cCNjOk::after {
  background: #3939a0 !important;
}
.cCNjOk::before {
  background: #3939a0 !important;
}
.eczmnE:not(.using-icon) {
  background: #3939a0 !important;
}
.eczmnE:not(.using-icon).active {
  background: #ffdf00 !important;
  border: 3px solid #3939a0 !important;
}

.iRCxYP {
  color: #7fbe67 !important;
  font-size: 22px !important;
}
.bLoSIz {
  height: 80px !important;
}
.iDyoWL {
  height: 80px !important;
}
.eczmnE {
  height: 20px !important;
  width: 20px !important;
}
.eczmnE:not(.using-icon) {
  background: #007fff;
}
.hashtagImg_sm {
  /* height: 100%; */
  /* width: 100%; */
}
.hashtagImg_sm > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tweet-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: auto;
}

.css-o69gx8-MuiCardMedia-root {
  max-width: 200px;
  max-height: 300px;
}

.beforeafterCard {
  background: linear-gradient(to right, #403586 0%, #2a2358 14%, #38306e 39%, #5b4fa4 100%);
  width: 45vw !important;
}
.slick-track {
  justify-content: center !important;
}

.img-box {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.white {
  color: white;
  font-weight: 600 !important;
}
.green {
  color: #7fbe67;
  font-weight: 600 !important;
}

/* .demoPage img {
  width: 380px;
  height: 600px;
} */

.demoPage img {
  max-width: 100%;
  height: 100%;
}
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.stf__item {
  -webkit-transition: -webkit-box-shadow 0.5s;
  -moz-transition: -moz-box-shadow 0.5s;
  -o-transition: -webkit-box-shadow 0.5s;
  -ms-transition: -ms-box-shadow 0.5s;
  -webkit-box-shadow: 0 0 10px #999;
  -moz-box-shadow: 0 0 10px #999;
  -ms-box-shadow: 0 0 10px #999;
  -o-box-shadow: 0 0 10px #999;
  box-shadow: 0 0 10px #999;
}
.css-f5evrp-MuiGrid-root {
  margin-left: 0 !important;
}

.css-f5evrp-MuiGrid-root > .MuiGrid-item {
  padding-left: 0 !important;
}
.css-8cfk28 {
  background-color: transparent !important;
}
input:-internal-autofill-selected {
  background-color: none !important;
}
.css-1fm9pd3-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root :hover {
  background-color: #b9964597 !important;
}

.css-ssm1fv-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-input {
  border-color: #000 !important;
}

.book_img {
  height: 45vh;
  cursor: pointer;
}
.css-11awamq-MuiFormLabel-root-MuiInputLabel-root {
  top: -11px !important;
}
.css-46bh2p-MuiCardContent-root {
  padding: 0 !important;
}
.certificate-canvas {
  height: 60vh;
  width: auto;
  box-shadow: rgba(128, 128, 128, 0.48) 1px 4px 16px;
}

.flip-book .controls .ctrl {
  position: absolute;
  bottom: 10px;
  transform: translate(-50%, -50%) !important;
  left: 50% !important;
}
