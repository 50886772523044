.fb3d-fullscreenx {
  position: fixed !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 2147483647 !important;
  margin: 0 !important;
  box-sizing: border-box !important;
  background-color: #333;
}

.toolbar {
  pointer-events: all;
  display: inline-block;
  background-image: linear-gradient(to bottom, #fff 0, #f8f8f8 100%);
  background-repeat: repeat-x;
  padding: 3px;
  background-color: #f8f8f8;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0px 0px 5px rgba(200, 200, 200, 0.75);
}
.ctrls .prev {
  left: 20px !important;
}

.ctrls .prev,
.ctrls .next {
  position: absolute !important;
  top: 50% !important;
  transform: translate(0, -50%) !important;
}
.prev {
  z-index: 100000 !important;
  background: red !important;
  height: 50px !important;
  width: 50px !important;
}
.next {
  z-index: 100000 !important;
  background: red !important;
  height: 50px !important;
  width: 50px !important;
}
